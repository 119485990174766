export default {
	900: {
		早餐: [
			['粥（克）', 50],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 50],
		],
		午餐: [
			['米饭（熟）（克）', 75],
			['杂粮（生）（克）', 0],
			['五花肉（克）', 25],
			['蔬菜（克）', 200],
			['北豆腐（老）（克）', 50],
		],
		晚餐: [
			['米饭（熟）（克）', 50],
			['鱼虾蟹（克）', 25],
			['蔬菜（克）', 200],
		],
		加餐: [
			['水果（克）', 150],
			['坚果', 20],
		],
		其他: [
			['用油（克）', 15],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	1800: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 100],
		],
		午餐: [
			['米饭（熟）（克）', 200],
			['杂粮（生）（克）', 50],
			['五花肉（克）', 100],
			['蔬菜（克）', 300],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 100],
			['鱼虾蟹（克）', 50],
			['蔬菜（克）', 250],
		],
		加餐: [
			['水果（克）', 300],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 20],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	2700: {
		早餐: [
			['粥（克）', 150],
			['液体奶（ml）', 250],
			['蛋类', 100],
			['蔬菜（克）', 250],
		],
		午餐: [
			['米饭（熟）（克）', 300],
			['杂粮（生）（克）', 150],
			['五花肉（克）', 150],
			['蔬菜（克）', 300],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 125],
			['鱼虾蟹（克）', 100],
			['蔬菜（克）', 250],
		],
		加餐: [
			['水果（克）', 350],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 25],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	1300: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 50],
		],
		午餐: [
			['米饭（熟）（克）', 150],
			['杂粮（生）（克）', 25],
			['五花肉（克）', 50],
			['蔬菜（克）', 250],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 50],
			['鱼虾蟹（克）', 50],
			['蔬菜（克）', 200],
		],
		加餐: [
			['水果（克）', 200],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 20],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	2200: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 150],
		],
		午餐: [
			['米饭（熟）（克）', 250],
			['杂粮（生）（克）', 100],
			['五花肉（克）', 125],
			['蔬菜（克）', 300],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 100],
			['鱼虾蟹（克）', 75],
			['蔬菜（克）', 250],
		],
		加餐: [
			['水果（克）', 350],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 25],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	800: {
		早餐: [
			['粥（克）', 50],
			['液体奶（ml）', 200],
			['蛋类', 50],
			['蔬菜（克）', 50],
		],
		午餐: [
			['米饭（熟）（克）', 50],
			['杂粮（生）（克）', 0],
			['五花肉（克）', 25],
			['蔬菜（克）', 200],
			['北豆腐（老）（克）', 50],
		],
		晚餐: [
			['米饭（熟）（克）', 25],
			['鱼虾蟹（克）', 25],
			['蔬菜（克）', 200],
		],
		加餐: [
			['水果（克）', 150],
			['坚果', 10],
		],
		其他: [
			['用油（克）', 15],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	1700: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 100],
		],
		午餐: [
			['米饭（熟）（克）', 150],
			['杂粮（生）（克）', 50],
			['五花肉（克）', 100],
			['蔬菜（克）', 250],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 100],
			['鱼虾蟹（克）', 50],
			['蔬菜（克）', 200],
		],
		加餐: [
			['水果（克）', 250],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 20],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	2600: {
		早餐: [
			['粥（克）', 150],
			['液体奶（ml）', 250],
			['蛋类', 100],
			['蔬菜（克）', 200],
		],
		午餐: [
			['米饭（熟）（克）', 300],
			['杂粮（生）（克）', 150],
			['五花肉（克）', 125],
			['蔬菜（克）', 300],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 125],
			['鱼虾蟹（克）', 100],
			['蔬菜（克）', 250],
		],
		加餐: [
			['水果（克）', 350],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 25],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	1200: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 50],
		],
		午餐: [
			['米饭（熟）（克）', 100],
			['杂粮（生）（克）', 25],
			['五花肉（克）', 50],
			['蔬菜（克）', 250],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 50],
			['鱼虾蟹（克）', 25],
			['蔬菜（克）', 150],
		],
		加餐: [
			['水果（克）', 150],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 20],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	2100: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 150],
		],
		午餐: [
			['米饭（熟）（克）', 250],
			['杂粮（生）（克）', 100],
			['五花肉（克）', 100],
			['蔬菜（克）', 300],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 100],
			['鱼虾蟹（克）', 75],
			['蔬菜（克）', 250],
		],
		加餐: [
			['水果（克）', 300],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 25],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	3000: {
		早餐: [
			['粥（克）', 150],
			['液体奶（ml）', 300],
			['蛋类', 100],
			['蔬菜（克）', 300],
		],
		午餐: [
			['米饭（熟）（克）', 325],
			['杂粮（生）（克）', 150],
			['五花肉（克）', 200],
			['蔬菜（克）', 350],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 150],
			['鱼虾蟹（克）', 100],
			['蔬菜（克）', 300],
		],
		加餐: [
			['水果（克）', 350],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 25],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	1600: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 100],
		],
		午餐: [
			['米饭（熟）（克）', 150],
			['杂粮（生）（克）', 50],
			['五花肉（克）', 75],
			['蔬菜（克）', 250],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 100],
			['鱼虾蟹（克）', 50],
			['蔬菜（克）', 200],
		],
		加餐: [
			['水果（克）', 250],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 20],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	2500: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 200],
		],
		午餐: [
			['米饭（熟）（克）', 300],
			['杂粮（生）（克）', 150],
			['五花肉（克）', 125],
			['蔬菜（克）', 300],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 125],
			['鱼虾蟹（克）', 100],
			['蔬菜（克）', 250],
		],
		加餐: [
			['水果（克）', 350],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 25],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	1100: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 50],
		],
		午餐: [
			['米饭（熟）（克）', 100],
			['杂粮（生）（克）', 25],
			['五花肉（克）', 50],
			['蔬菜（克）', 200],
			['北豆腐（老）（克）', 50],
		],
		晚餐: [
			['米饭（熟）（克）', 50],
			['鱼虾蟹（克）', 25],
			['蔬菜（克）', 150],
		],
		加餐: [
			['水果（克）', 100],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 20],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	2000: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 150],
		],
		午餐: [
			['米饭（熟）（克）', 250],
			['杂粮（生）（克）', 75],
			['五花肉（克）', 100],
			['蔬菜（克）', 300],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 100],
			['鱼虾蟹（克）', 75],
			['蔬菜（克）', 250],
		],
		加餐: [
			['水果（克）', 300],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 25],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	2900: {
		早餐: [
			['粥（克）', 150],
			['液体奶（ml）', 300],
			['蛋类', 100],
			['蔬菜（克）', 250],
		],
		午餐: [
			['米饭（熟）（克）', 325],
			['杂粮（生）（克）', 150],
			['五花肉（克）', 175],
			['蔬菜（克）', 350],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 150],
			['鱼虾蟹（克）', 100],
			['蔬菜（克）', 300],
		],
		加餐: [
			['水果（克）', 350],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 25],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	1500: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 100],
		],
		午餐: [
			['米饭（熟）（克）', 150],
			['杂粮（生）（克）', 50],
			['五花肉（克）', 75],
			['蔬菜（克）', 250],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 75],
			['鱼虾蟹（克）', 50],
			['蔬菜（克）', 200],
		],
		加餐: [
			['水果（克）', 200],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 20],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	2400: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 150],
		],
		午餐: [
			['米饭（熟）（克）', 300],
			['杂粮（生）（克）', 125],
			['五花肉（克）', 125],
			['蔬菜（克）', 300],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 125],
			['鱼虾蟹（克）', 100],
			['蔬菜（克）', 250],
		],
		加餐: [
			['水果（克）', 350],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 25],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	1000: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 50],
		],
		午餐: [
			['米饭（熟）（克）', 100],
			['杂粮（生）（克）', 0],
			['五花肉（克）', 50],
			['蔬菜（克）', 200],
			['北豆腐（老）（克）', 50],
		],
		晚餐: [
			['米饭（熟）（克）', 50],
			['鱼虾蟹（克）', 25],
			['蔬菜（克）', 200],
		],
		加餐: [
			['水果（克）', 150],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 20],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	1900: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 100],
		],
		午餐: [
			['米饭（熟）（克）', 250],
			['杂粮（生）（克）', 75],
			['五花肉（克）', 100],
			['蔬菜（克）', 300],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 100],
			['鱼虾蟹（克）', 50],
			['蔬菜（克）', 250],
		],
		加餐: [
			['水果（克）', 300],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 20],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	2800: {
		早餐: [
			['粥（克）', 150],
			['液体奶（ml）', 300],
			['蛋类', 100],
			['蔬菜（克）', 250],
		],
		午餐: [
			['米饭（熟）（克）', 300],
			['杂粮（生）（克）', 150],
			['五花肉（克）', 150],
			['蔬菜（克）', 300],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 150],
			['鱼虾蟹（克）', 125],
			['蔬菜（克）', 300],
		],
		加餐: [
			['水果（克）', 350],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 25],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	1400: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 50],
		],
		午餐: [
			['米饭（熟）（克）', 150],
			['杂粮（生）（克）', 25],
			['五花肉（克）', 75],
			['蔬菜（克）', 250],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 50],
			['鱼虾蟹（克）', 50],
			['蔬菜（克）', 200],
		],
		加餐: [
			['水果（克）', 200],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 20],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
	2300: {
		早餐: [
			['粥（克）', 100],
			['液体奶（ml）', 250],
			['蛋类', 50],
			['蔬菜（克）', 150],
		],
		午餐: [
			['米饭（熟）（克）', 300],
			['杂粮（生）（克）', 100],
			['五花肉（克）', 125],
			['蔬菜（克）', 300],
			['北豆腐（老）（克）', 100],
		],
		晚餐: [
			['米饭（熟）（克）', 125],
			['鱼虾蟹（克）', 100],
			['蔬菜（克）', 250],
		],
		加餐: [
			['水果（克）', 350],
			['坚果', 0],
		],
		其他: [
			['用油（克）', 25],
			['食盐（克）', 6],
			['水（毫升）', 1500],
		],
	},
};
