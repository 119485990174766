<template>
  <div>
    <div class="search-box">
      <h2 class="search-title border-bottom">按条件搜索</h2>
      <Form class="search-form" :label-width="85">
        <Form-item label="报告日期">
          <Date-picker
            format="yyyy-MM-dd"
            type="daterange"
            placeholder="选择日期"
            @on-change="handleChangeReportDate"
            style="width:300px;margin-right:10px;"></Date-picker>
          <Button type="primary" @click="getReportList">搜索报告</Button>
        </Form-item>
        <Form-item label="选择对象">
          <Row v-if="!branchShow">
            <Col span="5">
            <span class="sub-label">省份：</span>
            <div class="sub-content">
              <Select clearable v-model="searchData.pid" placeholder="请选择..." @on-change="changePro">
                <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5" offset="1">
            <span class="sub-label">城市：</span>
            <div class="sub-content">
              <Select clearable v-model="searchData.cid" placeholder="请选择..." @on-change="changeCity">
                <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5" offset="1">
            <span class="sub-label">门店：</span>
            <div class="sub-content">
              <Select clearable v-model="searchData.sid" placeholder="请选择..." @on-change="changeStore">
                <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5" offset="1">
            <span class="sub-label">会员：</span>
            <div class="sub-content">
              <Select clearable v-model="uid" placeholder="请选择..." filterable @on-change="changeUser">
                <Option :value="v.id" v-for="v in userList" :key="v.id">{{v.true_name}}</Option>
              </Select>
            </div>
            </Col>
          </Row>
          <Row v-else>
            <Col span="5">
            <span class="sub-label">省份：</span>
            <div class="sub-content">
              <Input disabled v-model="proName"></Input>
            </div>
            </Col>
            <Col span="5" offset="1">
            <span class="sub-label">城市：</span>
            <div class="sub-content">
              <Input disabled v-model="cityName"></Input>
            </div>
            </Col>
            <Col span="5" offset="1">
            <span class="sub-label">门店：</span>
            <div class="sub-content">
              <Input disabled v-model="sName"></Input>
            </div>
            </Col>
            <Col span="5" offset="1">
            <span class="sub-label">会员：</span>
            <div class="sub-content">
              <Select clearable v-model="uid" placeholder="请选择..." filterable @on-change="changeUser">
                <Option :value="v.id" v-for="v in userList" :key="v.id">{{v.true_name}}</Option>
              </Select>
            </div>
            </Col>
          </Row>
        </Form-item>
        <Form-item label="报告条件">
          <Row>
            <Col span="10">
              <span class="sub-label">饮食数据：</span>
              <div class="sub-content">
                <Select clearable v-model="queryParams.meal" placeholder="请选择..." @on-change="changeMeal" filterable>
                  <Option :value="v.rq" v-for="v in mealList" :key="v.id">{{v.name}}</Option>
                </Select>
              </div>
            </Col>
            <Col span="10" offset="1">
              <span class="sub-label">运动开始日期：</span>
              <div class="sub-content">
                  <Date-picker
                  format="yyyy-MM-dd"
                  type="date"
                  :options="disabledDate"
                  placeholder="选择日期"
                  @on-change="handleChangeDate"
                  style="width:200px;margin-right:10px;"></Date-picker>
              </div>
            </Col>
          </Row>
        </Form-item>
        <Form-item label="查看项目">
          <Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">全选</Checkbox>
        </Form-item>
        <Form-item label="">
          <CheckboxGroup v-model="comparisonProject" @on-change="checkAllGroupChange">
            <Checkbox :label="v" v-for="(v, i) in itemArr" :key="i"></Checkbox>
          </CheckboxGroup>
        </Form-item>
        <Form-item label="">
          <Button @click="generateReport" style="margin-right: 4px;">生成报告</Button>
        </Form-item>
      </Form>
    </div>
    <div class="content-box mb20">
      <h2 class="content-title border-bottom">量化差值管理报告</h2>
      <div class="report-body">
        <Table
          :columns="reportColumns"
          :data="reportList"
          :height="220"
        ></Table>
        <div>
          <span class="records">共 {{reportTotal}} 条记录</span>
        </div>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="reportTotal"
              :current="reportCurrentPage"
              :page-size="reportData.size"
              @on-change="changeReportPage"></Page>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box mb20">
      <h2 class="content-title border-bottom">数据结果</h2>
      <quantify-one v-if="cacheProject.includes('摄入量评估')" :data="quantify1"></quantify-one>
      <quantify-two v-if="cacheProject.includes('各类食物摄入情况')" :data="quantify2"></quantify-two>
      <quantify-three v-if="cacheProject.includes('微量元素摄入情况')" :data="quantify3"></quantify-three>
      <div v-if="flag">
        <quantify-four v-if="cacheProject.includes('能量消耗管理')"></quantify-four>
        <quantify-five v-if="cacheProject.includes('差值量化管理')"></quantify-five>
        <quantify-six v-if="cacheProject.includes('量化管理处方')"></quantify-six>
        <rate v-if="cacheProject.includes('评价与反馈')"></rate>
      </div>
    </div>
    <div class="symbol-box tac" v-show="flag">
      <Button @click="windowZoomTip" type="primary">存为PDF</Button>
    </div>
    <Modal
      v-model="showModal"
      title="补充客户基本信息"
      :closable="false"
      :mask-closable="false"
      class-name="vertical-center-modal"
      :width="550"
    >
      <p style="margin-bottom: 20px;">是否患有以下疾病：</p>
      <CheckboxGroup v-model="disease">
        <Checkbox label="高血压"></Checkbox>
        <Checkbox label="糖尿病"></Checkbox>
        <Checkbox label="肥胖"></Checkbox>
        <Checkbox label="痛风"></Checkbox>
        <Checkbox label="高血脂"></Checkbox>
        <Checkbox label="骨质疏松"></Checkbox>
        <Checkbox label="结石"></Checkbox>
      </CheckboxGroup>
      <div slot="footer">
        <Button type="primary" @click="getReportData">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import qualityService from '@/services/qualityService';
import comparisonService from '@/services/comparisonService';
import reportSportsService from '@/services/reportSportsService';
import memberService from '@/services/memberService';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import QuantifyOne from './quantify-1';
import QuantifyTwo from './quantify-2';
import QuantifyThree from './quantify-3';
import QuantifyFour from './quantify-4';
import QuantifyFive from './quantify-5';
import QuantifySix from './quantify-6';
import rate from './rate';

export default {
	components: {
		QuantifyOne,
		QuantifyTwo,
		QuantifyThree,
		QuantifyFour,
		QuantifyFive,
		QuantifySix,
		rate,
	},
	data() {
		return {
			valid: '',
			searchData: {
				page: 1,
				size: 1000,
				pid: '',
				cid: '',
				sid: '',
				simple_query: 1,
			},
			branchShow: false,
			branch_id: [],
			power: [],
			proName: '',
			cityName: '',
			sName: '',
			storeList: [],
			proList: [],
			cityList: [],
			userList: [],
			mealList: [],
			uid: '',
			indeterminate: false,
			checkAll: false,
			itemArr: [
				'摄入量评估',
				'各类食物摄入情况',
				'微量元素摄入情况',
				'能量消耗管理',
				'差值量化管理',
				'量化管理处方',
			],
			comparisonProject: [],
			cacheProject: [],
			queryParams: {
				member_id: '',
				start_time: '',
				end_time: '',
				jblb: [],
				meal: '',
			},
			showModal: false,
			disease: [],
			quantify1: {
				srlpg: {
					bzsrzl: 0,
					sjsrzl: 0,
					yccz: 0,
					ts: '',
				},
				sdcnb: {
					dbz_bjz: 25,
					zf_bjz: 25,
					tshhw_bjz: 25,
					dbz_sjz: 25,
					zf_sjz: 25,
					tshhw_sjz: 25,
				},
				ccfpb: {
					zaoc_bzz: 0,
					zaoc_sjz: 0,
					zhongc_bzz: 0,
					zhongc_sjz: 0,
					wc_bzz: 0,
					wc_sjz: 0,
				},
			},
			quantify2: [],
			quantify3: [],
			flag: 0, // 数据标志位
			reportColumns: [
				{ title: '门店', key: 'md', align: 'center' },
				{ title: '顾客姓名', key: 'gkmc', align: 'center' },
				{ title: '报告名称', key: 'bgmc', align: 'center' },
				{ title: '出具日期', key: 'cjrq', align: 'center' },
				{ title: '检测师', key: 'jcs', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						const url = params.row.url;
						return h('div', [
							h('a', {
								class: 'icon_eye',
								attrs: {
									href: url,
									target: '_blank',
								},
							}),
							h('Icon', {
								attrs: {
									type: 'md-arrow-round-down',
									size: 22,
									color: '#fdad00',
								},
								style: { cursor: 'pointer' },
								on: {
									click: () => {
										const a = document.createElement('a');
										a.href = url;
										a.style.display = 'none';
										a.setAttribute('download', decodeURI(url.substring(url.lastIndexOf('/') + 1)));
										document.body.appendChild(a);
										a.click();
										document.body.removeChild(a);
									},
								},
							}),
						]);
					},
				},
			],
			reportList: [],
			reportTotal: 0,
			reportCurrentPage: 1,
			reportData: {
				type: 4,
				month_flag: 0,
				pic_type: 43,
				page: 1,
				size: 30,
				pid: '',
				cid: '',
				sid: '',
			},
		};
	},
	computed: {
		...mapGetters({ userInfo: 'reportUserData' }),
		disabledDate() {
			const self = this;
			return {
				disabledDate(date) {
					return (
						!self.queryParams.meal ||
						(date &&
							(date.valueOf() > dayjs(self.queryParams.meal).valueOf() ||
								date.valueOf() < dayjs(self.queryParams.meal).subtract(6, 'day').valueOf()))
					);
				},
			};
		},
	},
	created() {
		let list = JSON.parse(localStorage.getItem('userInfo'));
		qualityService.getQualitySelect().then((data) => {
			this.power = list.user_info.power;
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				if (
					this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
				) {
					this.branchShow = true;
					this.branch_id = list.user_info.branch;
					this.searchData.sid = this.branch_id[2];
					this.searchData.cid = this.branch_id[1];
					this.searchData.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
					this.changeStore();
				}
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	mounted() {
		this.getReportList();
	},
	methods: {
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			this.uid = '';
			if (this.searchData.sid) {
				comparisonService.getUserList(this.searchData).then((data) => {
					this.userList = data.list;
				});
			} else {
				this.userList = [];
			}
		},
		changeUser(val) {
			if (val) {
				reportSportsService.getDietTime({ member_id: val }).then((data) => {
					this.mealList = data;
				});
			}
		},
		changeMeal(val) {
			this.mealList.forEach((item) => {
				if (item.rq == val) {
					this.valid = item.valid;
				}
			});
			console.log(this.valid);
		},
		handleChangeDate(value) {
			this.queryParams.start_time = value;
			if (value) {
				this.queryParams.end_time = dayjs(value).add(6, 'd').format('YYYY-MM-DD');
			}
			this.setStartTime(this.queryParams.start_time);
		},
		handleCheckAll() {
			if (this.indeterminate) {
				this.checkAll = false;
			} else {
				this.checkAll = !this.checkAll;
			}
			this.indeterminate = false;

			if (this.checkAll) {
				this.comparisonProject = this.itemArr;
			} else {
				this.comparisonProject = [];
			}
		},
		checkAllGroupChange(data) {
			if (data.length === this.itemArr.length) {
				this.indeterminate = false;
				this.checkAll = true;
			} else if (data.length > 0) {
				this.indeterminate = true;
				this.checkAll = false;
			} else {
				this.indeterminate = false;
				this.checkAll = false;
			}
		},
		...mapActions(['setReportUserData', 'setYYAllData']),
		...mapMutations(['setSelectVisible', 'setCacheProject', 'setStartTime']),
		getHeadInfo() {
			reportSportsService.getHeadInfo({ member_id: this.uid }).then((data) => {
				this.setReportUserData(
					Object.assign(data, {
						date: dayjs().format('YYYY-MM-DD'),
						trainStartTime: dayjs(this.queryParams.start_time).format('MM月DD日'),
						trainEndTime: dayjs(this.queryParams.end_time).format('MM月DD日'),
					}),
				);
				this.showModal = true;
				reportSportsService.getDisease({ member_id: this.uid }).then((data) => {
					if (data) {
						this.disease = JSON.parse(data);
					}
				});
			});
		},
		getReportData() {
			this.queryParams.jblb = JSON.stringify(this.disease);
			this.queryParams.member_id = this.uid;
			localStorage.setItem('member_id', this.uid);
			this.setReportUserData(Object.assign(this.userInfo, { disease: this.disease }));
			reportSportsService.getReportData(this.queryParams).then((data) => {
				this.setYYAllData(data);
				this.showModal = false;
				this.quantify1 = { sdcnb: data.sdcnb, srlpg: data.srlpg, ccfpb: data.mcfpb };
				this.quantify2 = data.hlys;
				this.quantify3 = data.wlys;
				this.flag = 1;
			});
		},
		generateReport() {
			if (!this.uid) {
				this.$Message.error('请先选择客户');
				return;
			}
			if (!this.queryParams.meal) {
				this.$Message.error('请先选择饮食数据');
				return;
			}
			if (!this.queryParams.start_time) {
				this.$Message.error('请先选择运动开始时间');
				return;
			}
			if (this.valid == 0) {
				this.$Message.error('摄入量小于800kcal不可作为量化方案依据，请依据正常摄入值出具报告');
				return;
			}
			this.setSelectVisible(true);
			this.disease = [];
			this.cacheProject = this.comparisonProject;
			this.setCacheProject(this.comparisonProject);
			this.getHeadInfo();
		},
		windowZoomTip() {
			if (this.quantify2.length === 0) {
				this.$Message.error('请先生成报告');
				return;
			}
			this.$Modal.confirm({
				render: (h) => {
					return [
						h(
							'p',
							{
								style: { fontSize: '16px' },
							},
							'为了保证能够较好的导出报告，你需要确保：',
						),
						h(
							'p',
							{
								style: { marginTop: '15px', fontSize: '16px' },
							},
							'1. 显示器分辨率大于等于 1920 * 1080。',
						),
						h(
							'p',
							{
								style: { marginTop: '15px', fontSize: '16px' },
							},
							'2. 使用现代浏览器的最新版本，如 Chrome。',
						),
						h(
							'p',
							{
								style: { marginTop: '10px', fontSize: '16px', lineHeight: 1.5 },
							},
							'3. 浏览器缩放必须为 100%，可通过浏览器设置或者快捷键 CTRL + 0 重置缩放。',
						),
					];
				},
				onOk: () => {
					this.saveAsPDF();
				},
			});
		},
		saveAsPDF() {
			const loading = this.$loading({
				lock: true,
				text: '正在存储中，请稍等',
				spinner: 'el-icon-loading',
			});
			this.setSelectVisible(false);
			setTimeout(() => {
				const printTarget = document.querySelectorAll('.quantify-container');
				if (printTarget.length > 0) {
					const self = this;
					const pdf = new JsPDF({ compress: true, unit: 'pt' });
					Promise.all(
						Array.from(printTarget).map((item) =>
							html2canvas(item, {
								scale: 1,
								scrollX: 0,
								scrollY: -window.scrollY,
							}),
						),
					).then((canvasList) => {
						for (let i = 0; i < canvasList.length; i++) {
							const canvas = canvasList[i];
							const contentWidth = canvas.width;
							const contentHeight = canvas.height;
							const imgWidth = 555.28;
							const imgHeight = (imgWidth / contentWidth) * contentHeight;
							const pageData = canvas.toDataURL('image/png', 1);

							if (i !== 0) pdf.addPage();
							pdf.addImage(pageData, 'png', 20, 0, imgWidth, imgHeight);

							if (i === printTarget.length - 1) {
								const time = dayjs().format('YYYYMMDD');
								const fileName = `${self.userInfo.name}${time}量化差值管理报告.pdf`;
								const formData = new FormData();
								formData.append('photo', pdf.output('blob'));
								formData.append('check', 1);
								formData.append('name', fileName);
								memberService.uploadReport(formData).then((data) => {
									memberService
										.addReport({
											member_id: self.userInfo.member_id,
											type: 4,
											bgzl: 43,
											check_date: data.check_date,
											photo_id: data.id,
											bgms: '出具报告自动上传',
										})
										.then(() => {
											self.$Message.success('转存成功');
										})
										.catch(() => {
											self.$Message.success('转存失败，请手动上传');
										});
								});

								pdf.save(fileName);
								self.$nextTick(() => {
									loading.close();
								});
							}
						}
					});
				} else {
					this.$Message.warning('无法生成PDF，请检查报告是否存在');
				}
			}, 0);
		},
		handleChangeReportDate(value) {
			this.reportData.start_time = value[0];
			this.reportData.end_time = value[1];
		},
		getReportList() {
			this.reportData.pid = this.searchData.pid;
			this.reportData.cid = this.searchData.cid;
			this.reportData.sid = this.searchData.sid;
			this.reportData.member_id = this.uid;
			memberService.getReportList(this.reportData).then((data) => {
				this.reportList = data.list;
				this.reportTotal = data.row_size;
				this.reportCurrentPage = +data.cur_page;
			});
		},
		changeReportPage(page) {
			this.reportData.page = page;
			this.getReportList();
		},
	},
};
</script>

<style>
	.quantify {
		width: 1100px;
	}

	.sub-label {
		vertical-align: middle;
		float: left;
	}

	.sub-content {
		margin-left: 80px;
	}

	.content-title {
		height: 39px;
		line-height: 39px;
		padding-left: 10px;
		background-color: #fbfbfb;
	}

	.quantify-container {
		background-color: #fff;
		padding: 16px;
	}

  .content-box {
    background-color: #fff;
  }

  .report-body {
    margin: 10px;
  }
</style>
