<template>
  <div class="adjust-content">
    <div class="adjust-wrap">
      <h3 class="title">营养作业</h3>
      <div class="adjust-textarea">
        <div class="textarea">
          建议：
          <Button
            type="warning"
            @click="suggestSelectModal = true"
            style="font-size: 14px"
            v-if="selectVisible"
          >建议提示</Button>
          <div class="textarea-content" v-html="suggestContent"></div>
        </div>
        <div class="textarea">
          疾病：
          <Button
            type="warning"
            @click="dieaseSelectModal = true"
            style="font-size: 14px"
            v-if="selectVisible"
          >疾病提示</Button>
          <div class="textarea-content" v-html="dieaseContent"></div>
        </div>
      </div>
    </div>
    <Modal
      v-model="suggestSelectModal"
      title="建议提示"
      @on-ok="onSuggestHandle"
      width="70%"
      class="week-rate-iframe"
    >
      <Form>
        <Form-item label="建议：">
          <Select v-model="suggestValue" multiple>
            <Option
              v-for="(item, index) in suggestList"
              :value="item.value"
              :key="index"
            >{{ item.label }}</Option>
          </Select>
        </Form-item>
      </Form>
    </Modal>
    <!-- 疾病提示Modal -->
    <Modal
      v-model="dieaseSelectModal"
      title="疾病提示"
      @on-ok="onDieaseHandle"
      width="70%"
      class="week-rate-iframe"
    >
      <Form>
        <Row :gutter="10">
          <Col span="12" v-if="reportUserData.disease.indexOf('高血压') > -1">
            <Form-item label="高血压：">
              <Select v-model="hypertension" multiple @on-change="onChangeHypertension">
                <Option
                  v-for="(item, index) in hypertensionList"
                  :value="item.value"
                  :key="index * 8"
                >{{ item.label }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="12" v-if="reportUserData.disease.indexOf('糖尿病') > -1">
            <Form-item label="糖尿病：">
              <Select v-model="diabetes" multiple @on-change="onChangeDiabetes">
                <Option
                  v-for="(item, index) in diabetesList"
                  :value="item.value"
                  :key="index * 10"
                >{{ item.label }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="12" v-if="reportUserData.disease.indexOf('高血脂') > -1">
            <Form-item label="高血脂：">
              <Select v-model="HighBloodLipids" multiple @on-change="onChangeHighBlood">
                <Option
                  v-for="(item, index) in HighBloodLipidsList"
                  :value="item.value"
                  :key="index * 20"
                >{{ item.label }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="12" v-if="reportUserData.disease.indexOf('痛风') > -1">
            <Form-item label="嘌呤高/痛风：">
              <Select v-model="gout" multiple @on-change="onChangeGout">
                <Option
                  v-for="(item, index) in goutList"
                  :value="item.value"
                  :key="index * 30"
                >{{ item.label }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="12" v-if="reportUserData.disease.indexOf('肥胖') > -1">
            <Form-item label="肥胖：">
              <Select v-model="obesity" multiple @on-change="onChangeObesity">
                <Option
                  v-for="(item, index) in obesityList"
                  :value="item.value"
                  :key="index * 60"
                >{{ item.label }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="12" v-if="reportUserData.disease.indexOf('骨质疏松') > -1">
            <Form-item label="骨质疏松：">
              <Select v-model="skeleton" multiple @on-change="onChangeSketeton">
                <Option
                  v-for="(item, index) in skeletonList"
                  :value="item.value"
                  :key="index * 70"
                >{{ item.label }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="12" v-if="reportUserData.disease.indexOf('结石') > -1">
            <Form-item label="结石：">
              <Select v-model="stone" multiple @on-change="onChangeStone">
                <Option
                  v-for="(item, index) in stoneList"
                  :value="item.value"
                  :key="index * 80"
                >{{ item.label }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
      </Form>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
	data() {
		return {
			suggestSelectModal: false,
			dieaseSelectModal: false,
			suggestContent: '',
			dieaseContent: '',
			suggestValue: [],
			suggestList: [
				{
					value: 1,
					label: '食物种类多样，五大类食物齐全',
				},
				{
					value: 2,
					label: '三餐分配比例合理（3:4:3，代谢综合征考虑加餐)，早餐保证能量和优质蛋白的摄入',
				},
				{
					value: 3,
					label: '优质蛋白占总蛋白1/3以上，各微量营养素摄入合理',
				},
				{
					value: 4,
					label: '烹饪方式合理，以速炒、炖、蒸、煮为主',
				},
				{
					value: 5,
					label: '吃饭顺序：先喝汤，吃青菜，蛋白质，肉类脂肪+米饭',
				},
				{
					value: 6,
					label: '认真将饮食记录上传康一生APP记录，便于实际营养评估分析',
				},
				{
					value: 7,
					label: '认真记录饮食，让评估的数据更加准确，阶段性指导更加有效',
				},
				{
					value: 8,
					label:
						'食物种类丰富，营养均衡。少吃肥甘厚腻、油炸、熏烤、过甜、过咸和过辣食物；烹调尽量采取煮、蒸、炝拌等方法，保证膳食清淡、容易消化吸收',
				},
				{
					value: 9,
					label:
						'多喝白开水，绿茶、自制新鲜果汁或用罗汉果、无花果、大枣、黄芪、西洋参等煲制的清火利咽、补气补血的饮品',
				},
				{
					value: 10,
					label:
						'富含充足维生素的食物：胡萝卜、芦笋、绿豆芽和黄豆芽、柿子椒、西红柿、菠菜、白菜、芹菜、西兰花、包菜、莴苣、洋葱、大葱等',
				},
				{
					value: 11,
					label:
						'包含矿物质锌、硒、铁的食物：青稞、藜麦、燕麦、大麦、黑米、薏仁米、鲜玉米、红薯、山药、海带、蘑菇、银耳、木耳、深海鱼类等',
				},
				{
					value: 12,
					label: '日常多次少量饮水1500-1700ml',
				},
			],
			hypertension: [],
			hypertensionText: '',
			hypertensionList: [
				{
					value: 1,
					label: '控制总能量，防止肥胖的发生',
				},
				{
					value: 2,
					label:
						'钠盐摄入不超过6g，可选择低钠盐（不可使用人群：1.肾功能不全及血钾高的人群。2.低血钠患者。3.服用普利类及沙坦类降压药物的人）',
				},
				{
					value: 3,
					label: '选择优质蛋白，减少肾脏负担',
				},
				{
					value: 4,
					label: '烹饪方式合理，以速炒、炖、蒸、煮为主',
				},
			],
			diabetes: [],
			diabetesText: '',
			diabetesList: [
				{
					value: 1,
					label: '控制总热量，少食多餐',
				},
				{
					value: 2,
					label: '选择低GI值的食物（详情请参见GI值表）',
				},
				{
					value: 3,
					label: '增加蔬菜杂粮的摄入，补充膳食纤维',
				},
				{
					value: 4,
					label: '补充丰富的维生素和无机盐：VB、VC、铬、锌、钙',
				},
				{
					value: 5,
					label: '可通过代餐来减少热量摄入，调节血糖血脂代谢',
				},
			],
			HighBloodLipids: [],
			HighBloodLipidsText: '',
			HighBloodLipidsList: [
				{
					value: 1,
					label: '控制总能量，防止肥胖的发生',
				},
				{
					value: 2,
					label: '严格控制脂肪的摄入，选择不饱和脂肪酸',
				},
				{
					value: 3,
					label: `严格限制饮食中的胆固醇摄人
						胆固醇高的食物有：
						①比如猪油、黄油、动物内脏，如猪肝、猪肠子、猪肚等；
						②蛋黄类，有鸡蛋黄、鸭蛋黄；
						③各种酒类、鱼籽、蟹籽、海鲜等；
						④糖分是胆固醇的主要原料，高糖食物主要包括：蜂蜜、白糖、红糖、面粉、甘蔗、地瓜、大枣、甜菜等。`,
				},
				{
					value: 4,
					label: `选择抗氧化的食物
						例如：
						①富含维生素C的食物，比如橘子、橙子、柚子，这些食物当中含有的维生素C有很好的抗氧化作用；
						②富含有番茄红素的食物，比如西红柿，因为富含丰富的番茄红素，在抗氧化方面的功效非常明显；
						③富含有维生素A的食物，比如胡萝卜等`,
				},
			],
			gout: [],
			goutText: '',
			goutList: [
				{
					value: 1,
					label: '控制总能量，少量多次喝水，防止痛风加重',
				},
				{
					value: 2,
					label:
						'减少摄入含嘌呤成分高的食物（可先焯水，不喝浓汤，注意豆类、鱼类的摄入，详情可以参考嘌呤表）',
				},
				{
					value: 3,
					label: `选择优质蛋白，
						①肉类主要是指瘦的猪肉，羊肉，牛肉，鸡肉，还有鱼，海鲜，虾等食物都有很高的优质蛋白，同时，脂肪含量相对较低。
						②奶制品，比如脱脂的纯牛奶就是典型的优质高蛋白食物；
						③各种蛋类，比如鸡蛋，鹅蛋，鹌鹑蛋等都属于优质高蛋白食物。
						④豆类，比如说黄豆，黑豆，大豆，绿豆等`,
				},
				{
					value: 4,
					label: '多食蔬菜，每天摄入300-500克蔬菜',
				},
				{
					value: 5,
					label: '多食水果，每天摄入200-350克水果',
				},
				{
					value: 6,
					label: '限酒，酒精增多乳酸堆积，阻碍嘌呤代谢',
				},
			],
			obesity: [],
			obesityText: '',
			obesityList: [
				{
					value: 1,
					label: `可通过代餐来减少热量摄入，调节血糖血脂代谢。`,
				},
				{
					value: 2,
					label: `多食用新鲜蔬菜水果藻类等，以增加维生素、矿物质和食物纤维的供给量。`,
				},
				{
					value: 3,
					label: `控制主食，限制纯糖和甜食。主食可采用递减法，一日三餐减去50克。逐步将主食控制在标准范围。`,
				},
			],
			skeleton: [],
			skeletonText: '',
			skeletonList: [
				{
					value: 1,
					label: '多吃一些含钙高的食物，比如说牛奶、豆制品、卷心菜、萝卜、芥菜、甘蓝等。',
				},
				{
					value: 2,
					label:
						'限制糖、盐的摄入，及时补充锰和镁等（豆类、麦仁及黑大米），这样才能够保存体内的钙质。',
				},
				{
					value: 3,
					label: '减少咖啡、浓茶及富磷食物的摄入：动物肝脏、碳酸饮料、可口可乐等含磷较高。',
				},
			],
			stone: [],
			stoneText: '',
			stoneList: [
				{
					value: 1,
					label: `每天要喝2000-3000ml以上的温开水，少喝含矿物质多的硬水、茶水。`,
				},
				{
					value: 2,
					label: `少吃豆制品、草酸盐含量高（番茄，菠菜，草莓，巧克力等）、胆固醇多（肥肉，动物肝脏等）的食物。`,
				},
			],
		};
	},
	computed: {
		...mapGetters(['reportUserData', 'selectVisible', 'yyAllData']),
	},
	methods: {
		...mapMutations(['setNorishmentSuggestion', 'setNorishmentDiease']),
		onSuggestHandle() {
			let text = '';
			if (this.suggestValue.length) {
				this.suggestValue.forEach((item, index) => {
					text += `<p>${index + 1}、${this.suggestList[item - 1].label}</p>`;
				});
				this.suggestContent = text;
			} else {
				this.suggestContent = '';
			}
			this.setNorishmentSuggestion(this.suggestContent);
		},
		onDieaseHandle() {
			this.dieaseContent = `
				<p>${this.diabetesText}</p>
				<p>${this.hypertensionText}</p>
				<p>${this.HighBloodLipidsText}</p>
				<p>${this.skeletonText}</p>
				<p>${this.goutText}</p>
				<p>${this.obesityText}</p>
				<p>${this.stoneText}</p>
				`;
			this.setNorishmentDiease(this.dieaseContent);
		},
		onChangeDiabetes(val) {
			this.diabetes = val;
			let text = '';
			if (val.length) {
				this.diabetes.forEach((item, index) => {
					text += `<p>${index + 1}、${this.diabetesList[item - 1].label}</p>`;
				});
				this.diabetesText = `糖尿病：${text}`;
			} else {
				this.diabetesText = '';
			}
		},
		onChangeHypertension(val) {
			this.hypertension = val;
			let text = '';
			if (val.length) {
				this.hypertension.forEach((item, index) => {
					text += `<p>${index + 1}、${this.hypertensionList[item - 1].label}</p>`;
				});
				this.hypertensionText = `高血压：${text}`;
			} else {
				this.hypertensionText = '';
			}
		},
		onChangeHighBlood(val) {
			this.HighBloodLipids = val;
			let text = '';
			if (val.length) {
				this.HighBloodLipids.forEach((item, index) => {
					text += `<p>${index + 1}、${this.HighBloodLipidsList[item - 1].label}</p>`;
				});
				this.HighBloodLipidsText = `高血脂：${text}`;
			} else {
				this.HighBloodLipidsText = '';
			}
		},
		onChangeGout(val) {
			this.gout = val;
			let text = '';
			if (val.length) {
				this.gout.forEach((item, index) => {
					text += `<p>${index + 1}、${this.goutList[item - 1].label}</p>`;
				});
				this.goutText = `痛风：${text}`;
			} else {
				this.goutText = '';
			}
		},
		onChangeSketeton(val) {
			this.skeleton = val;
			let text = '';
			if (val.length) {
				this.skeleton.forEach((item, index) => {
					text += `<p>${index + 1}、${this.skeletonList[item - 1].label}</p>`;
				});
				this.skeletonText = `骨质疏松：${text}`;
			} else {
				this.skeletonText = '';
			}
		},
		onChangeObesity(val) {
			this.obesity = val;
			let text = '';
			if (val.length) {
				this.obesity.forEach((item, index) => {
					text += `<p>${index + 1}、${this.obesityList[item - 1].label}</p>`;
				});
				this.obesityText = `肥胖：${text}`;
			} else {
				this.obesityText = '';
			}
		},
		onChangeStone(val) {
			this.stone = val;
			let text = '';
			if (val.length) {
				this.stone.forEach((item, index) => {
					text += `<p>${index + 1}、${this.stoneList[item - 1].label}</p>`;
				});
				this.stoneText = `结石：${text}`;
			} else {
				this.stoneText = '';
			}
		},
	},
	watch: {
		yyAllData() {
			this.suggestContent = '';
			this.dieaseContent = '';
			this.suggestValue = [];
			this.diabetes = [];
			this.hypertension = [];
			this.HighBloodLipids = [];
			this.setNorishmentSuggestion(this.suggestContent);
			this.setNorishmentDiease(this.dieaseContent);
		},
	},
};
</script>

<style lang="less" scoped>
.adjust-content {
  .adjust-wrap {
    margin-top: 20px;

    &.border {
      border-top: 1px solid #005ab3;
    }

    .title {
      display: inline-block;
      background-color: #005ab3;
      font-size: 20px;
      padding: 5px 28px;
      color: #fff;
      font-weight: 700;
      margin: 15px 0;
    }

    .plan-wrap {
      display: flex;
      align-items: center;

      .adj-img {
        width: 50px;
        margin-right: 10px;
      }

      .plan {
        color: #005ab3;
        font-weight: 700;
        font-size: 22px;
        padding: 20px 0;

        > span {
          padding-bottom: 6px;
          font-weight: 400;
          border-bottom: 1px solid #005ab3;
        }
      }
    }

    .plan-list {
      > li {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #005ab3;
        padding: 10px 60px;

        .plan-item {
          margin-right: 80px;
          text-align: center;

          .plan-item-img {
            width: 55px;
            margin-bottom: 5px;
          }

          .plan-item-text {
            font-size: 20px;
            font-weight: 700;
            color: #005ab3;
          }
        }

        .plan-card {
          color: #71a93b;
          display: flex;
          flex: 1;

          .plan-card-item {
            display: flex;
            flex: 1;

            .pci-food {
              margin-right: 15px;
              text-align: center;

              > img {
                width: 55px;
                margin-bottom: 5px;
              }

              .pci-food-text {
                font-size: 18px;
                font-weight: 700;
              }
            }

            .pci-text {
              font-size: 16px;

              > p {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }

    .adjust-textarea {
      display: block;
      width: 100%;
      border: 1px solid #005ab3;
      margin-bottom: 10px;
      padding: 8px;
      .textarea {
        color: #005ab3;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 15px;
      }
      .textarea-content {
        padding: 10px 0;
      }
    }
  }
}
</style>
