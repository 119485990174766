<template>
  <div class="adjust-container">
    <navi-title title_ch="调整方案" title_en="ADJUSTMENT PLAN">
      <div class="daily-score">
        <Input-number :min="1" v-model="sport_score" style="width: 63px" @on-change="onChangeScore"></Input-number>
        <span>分</span>
      </div>
    </navi-title>
    <div class="adjust-content">
      <div class="adjust-wrap border">
        <h3 class="title">运动方案</h3>
        <ul class="adjust-list">
          <li>
            <img src="@/assets/img/manage/10-1.png" alt="img" class="adj-img" />
            <p class="adj-text">有效消耗量</p>
            <p class="adj-kcal">{{ ((totalConsume * 2) / 3).toFixed(2) }}kcal</p>
          </li>
          <li>
            <img src="@/assets/img/manage/10-2.png" alt="img" class="adj-img" />
            <p class="adj-text">总消耗</p>
            <p class="adj-kcal">{{ totalConsume }}kcal</p>
          </li>
          <li>
            <img src="@/assets/img/manage/10-3.png" alt="img" class="adj-img" />
            <p class="adj-text">有效运动时长</p>
            <p class="adj-kcal">{{ Math.ceil(150 / count) }}min</p>
          </li>
          <li>
            <img src="@/assets/img/manage/10-4.png" alt="img" class="adj-img" />
            <p class="adj-text">有效运动率</p>
            <p class="adj-kcal">66%</p>
          </li>
        </ul>

        <ul class="adjust-list adjust-sports">
          <li>
            <img src="@/assets/img/manage/10-5.png" alt="img" class="adj-img" />
            <p class="adj-text">运动频次</p>
            <p class="adj-kcal">
              一周&nbsp;&nbsp;
              <Input-number :min="0" v-model="count" @on-change="onChangeCount"></Input-number>&nbsp;&nbsp;次
            </p>
          </li>
          <li>
            <img src="@/assets/img/manage/10-6.png" alt="img" class="adj-img" />
            <p class="adj-text">一周标准值</p>
            <p class="adj-kcal">150min</p>
          </li>
          <li>
            <img src="@/assets/img/manage/10-7.png" alt="img" class="adj-img" />
            <p class="adj-text">运动场地</p>
            <p class="adj-kcal">
              <Input type="text" v-model="site" placeholder="请输入" @on-change="onChangeSite"></Input>
            </p>
          </li>
        </ul>
      </div>

      <div class="adjust-wrap">
        <h3 class="title">运动作业</h3>
        <div class="adjust-textarea">
          <div class="textarea">
            建议：
            <Button
              type="warning"
              @click="suggestSelectModal = true"
              style="font-size: 14px"
              v-if="selectVisible"
            >建议提示</Button>
            <div class="textarea-content" v-html="suggestContent"></div>
          </div>
          <div class="textarea" v-if="reportUserData.disease.length">
            疾病：
            <Button
              type="warning"
              @click="dieaseSelectModal = true"
              style="font-size: 14px"
              v-if="selectVisible"
            >疾病提示</Button>
            <div class="textarea-content" v-html="dieaseContent"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="rate-date">
      <p>下次交作业日期</p>
      <img src="@/assets/img/manage/date-icon.png" alt="img" />
      <Date-picker v-model="nextWorkDate" type="date" placeholder="选择日期" style="width: 200px"></Date-picker>
    </div>
    <!-- 建议提示Modal -->
    <Modal
      v-model="suggestSelectModal"
      title="建议提示"
      @on-ok="onSuggestHandle"
      width="70%"
      class="week-rate-iframe"
    >
      <Form>
        <Form-item label="建议：">
          <Select v-model="suggestValue" multiple>
            <Option
              v-for="item in suggestList"
              :value="item.value"
              :key="item.label"
            >{{ item.label }}</Option>
          </Select>
        </Form-item>
      </Form>
    </Modal>
    <!-- 疾病提示Modal -->
    <Modal
      v-model="dieaseSelectModal"
      title="疾病提示"
      @on-ok="onDieaseHandle"
      width="70%"
      class="week-rate-iframe"
    >
      <Form>
        <Row :gutter="10">
          <Col span="12" v-if="reportUserData.disease.indexOf('糖尿病') > -1">
            <Form-item label="糖尿病：">
              <Select v-model="diabetes" multiple @on-change="onChangeDiabetes">
                <Option
                  v-for="(item, index) in diabetesList"
                  :value="item.value"
                  :key="index"
                >{{ item.label }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="12" v-if="reportUserData.disease.indexOf('高血压') > -1">
            <Form-item label="高血压：">
              <Select v-model="hypertension" multiple @on-change="onChangeHypertension">
                <Option
                  v-for="(item, index) in hypertensionList"
                  :value="item.value"
                  :key="index * 8"
                >{{ item.label }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="12" v-if="reportUserData.disease.indexOf('高血脂') > -1">
            <Form-item label="高血脂：">
              <Select v-model="HighBloodLipids" multiple @on-change="onChangeHighBlood">
                <Option
                  v-for="(item, index) in HighBloodLipidsList"
                  :value="item.value"
                  :key="index * 10"
                >{{ item.label }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="12" v-if="reportUserData.disease.indexOf('骨质疏松') > -1">
            <Form-item label="骨质疏松：">
              <Select v-model="skeleton" multiple @on-change="onChangeSketeton">
                <Option
                  v-for="(item, index) in skeletonList"
                  :value="item.value"
                  :key="index * 20"
                >{{ item.label }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="12" v-if="reportUserData.disease.indexOf('痛风') > -1">
            <Form-item label="痛风：">
              <Select v-model="gout" multiple @on-change="onChangeGout">
                <Option
                  v-for="(item, index) in goutList"
                  :value="item.value"
                  :key="index * 20"
                >{{ item.label }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="12" v-if="reportUserData.disease.indexOf('肥胖') > -1">
            <Form-item label="肥胖：">
              <Select v-model="obesity" multiple @on-change="onChangeObesity">
                <Option
                  v-for="(item, index) in obesityList"
                  :value="item.value"
                  :key="index * 40"
                >{{ item.label }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="12" v-if="reportUserData.disease.indexOf('结石') > -1">
            <Form-item label="结石：">
              <Select v-model="stone" multiple @on-change="onChangeStone">
                <Option
                  v-for="(item, index) in stoneList"
                  :value="item.value"
                  :key="index * 50"
                >{{ item.label }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
      </Form>
    </Modal>
  </div>
</template>

<script>
import naviTitle from 'components/naviTitle/title';
import { mapGetters, mapMutations } from 'vuex';
export default {
	data() {
		return {
			count: 1,
			site: '',
			score: 0,
			suggestSelectModal: false,
			dieaseSelectModal: false,
			suggestContent: '',
			dieaseContent: '',
			suggestValue: [],
			suggestList: [
				{
					value: 1,
					label: '注意运动前后的水分补充及血压等数据检测',
				},
				{
					value: 2,
					label: '一定做到运动的三部曲：预热——训练——放松，进行膝关节及机体的保护',
				},
				{
					value: 3,
					label:
						'一定记得运动时用康一生记录运动数据，便于更好的了解实际运动习惯与运动误区，更好的进行指导调整',
				},
				{
					value: 4,
					label: '运动时穿运动鞋及宽松的衣服',
				},
				{
					value: 5,
					label:
						'选择环境优美的地方：郊外、公园、湖边、草地旁等可以亲近大自然的地方。这些地方环境优美，鸟语花香，有绿树、鲜花、小鸟、蝴蝶的环绕和陪伴，宜人的环境让人有赏心悦目的感觉。远离汽车尾气和道路噪音的污染和干扰，自然会令人心情舒畅。清新的空气，还能给人更多的氧气，让有氧运动进行得更彻底',
				},
			],
			diabetes: [],
			diabetesText: '',
			diabetesList: [
				{
					value: 1,
					label: '一般建议采用用餐半小时或一小时后进行运动',
				},
				{
					value: 2,
					label:
						'结合OGTT检测了解真实自己的胰岛功能情况确定合适的时间点进行运动消耗：5点血糖监测法（空腹、餐后半小时、一小时、两小时、三小时）',
				},
				{
					value: 3,
					label:
						'结合检测数据确定合适的时间点进行运动消耗：9+1点血糖监测法（空腹、餐后半小时、一小时、两小时、三小时、午餐前、餐后两小时、晚餐前、餐后两小时、睡觉前）',
				},
			],
			hypertension: [],
			hypertensionText: '',
			hypertensionList: [
				{
					value: 1,
					label: '总原则是运动强度宜小不宜大，强调以中小强度，以放松性质的练习为主',
				},
				{
					value: 2,
					label:
						'运动频率：每周至少锻炼3天，每周间隔时间不超过2天，适应后在酌情增加运动次数，逐步达到预期频率',
				},
				{
					value: 3,
					label:
						'如果安静时血压收缩压高于150mmHg,或舒张压高于90mmHg, 应暂停运动，以律动或是散步等温和的运动方式代替',
				},
			],
			HighBloodLipids: '',
			HighBloodLipidsText: '',
			HighBloodLipidsList: [
				{
					value: 1,
					label:
						'运动的频率和时间：每周累计至少150分钟（2.5小时），如每天30分钟，每周5天。或者每次10分钟，一天3次，一周5天',
				},
				{
					value: 2,
					label: '中等运动强度，按照具体处方指导进行',
				},
			],
			skeleton: [],
			skeletonText: '',
			skeletonList: [
				{
					value: 1,
					label: '频率：每周3～5天。运动时间：每次20～30分钟。运动强度：心率到达170-年龄即可',
				},
				{
					value: 2,
					label: '注意把控运动强度，按照方案执行不要自行增加运动强度与运动量',
				},
				{
					value: 3,
					label:
						'增加日光照射，每天至少要进行15至60分钟的户外活动，日光照射能增强人体的维生素D，可以帮助人体吸收钙，增强骨骼',
				},
			],
			gout: [],
			goutText: '',
			goutList: [
				{
					value: 1,
					label: `发作期以静养为主，少量多次饮水`,
				},
				{
					value: 2,
					label: `缓解期正常每周150分钟有氧运动为主`,
				},
			],
			obesity: [],
			obesityText: '',
			obesityList: [
				{
					value: 1,
					label: `BMI较大及膝关节不好的，建议游泳、磁控车或者律动训练`,
				},
			],
			stone: [],
			stoneText: '',
			stoneList: [
				{
					value: 1,
					label: `可以加入跳绳或垂直律动等训练方式`,
				},
			],
		};
	},
	computed: {
		...mapGetters([
			'quantitative_xh',
			'yyAllData',
			'reportUserData',
			'selectVisible',
			'nextWorkDate',
			'sport_score',
		]),
		totalConsume() {
			// 总消耗
			return this.yyAllData.czgl.lhzb.sjxh + this.quantitative_xh;
		},
	},
	components: {
		naviTitle,
	},
	methods: {
		...mapMutations([
			'setSportScore',
			'setAdjustmentSite',
			'setAdjustmentSuggestion',
			'setAdjustmentDiease',
			'setAdjustmentCount',
		]),
		onSuggestHandle() {
			let text = '';
			if (this.suggestValue.length) {
				this.suggestValue.forEach((item, index) => {
					text += `<p>${index + 1}、${this.suggestList[item - 1].label}</p>`;
				});
				this.suggestContent = text;
			} else {
				this.suggestContent = '';
			}
			this.setAdjustmentSuggestion(this.suggestContent);
		},
		onDieaseHandle() {
			this.dieaseContent = `
				<p>${this.diabetesText}</p>
				<p>${this.hypertensionText}</p>
				<p>${this.HighBloodLipidsText}</p>
				<p>${this.skeletonText}</p>
				<p>${this.goutText}</p>
				<p>${this.obesityText}</p>
				<p>${this.stoneText}</p>
				`;
			this.setAdjustmentDiease(this.dieaseContent);
		},
		onChangeDiabetes(val) {
			this.diabetes = val;
			let text = '';
			if (val.length) {
				this.diabetes.forEach((item, index) => {
					text += `<p>${index + 1}、${this.diabetesList[item - 1].label}</p>`;
				});
				this.diabetesText = `糖尿病：${text}`;
			} else {
				this.diabetesText = '';
			}
		},
		onChangeHypertension(val) {
			this.hypertension = val;
			let text = '';
			if (val.length) {
				this.hypertension.forEach((item, index) => {
					text += `<p>${index + 1}、${this.hypertensionList[item - 1].label}</p>`;
				});
				this.hypertensionText = `高血压：${text}`;
			} else {
				this.hypertensionText = '';
			}
		},
		onChangeHighBlood(val) {
			this.HighBloodLipids = val;
			let text = '';
			if (val.length) {
				this.HighBloodLipids.forEach((item, index) => {
					text += `<p>${index + 1}、${this.HighBloodLipidsList[item - 1].label}</p>`;
				});
				this.HighBloodLipidsText = `高血脂：${text}`;
			} else {
				this.HighBloodLipidsText = '';
			}
		},
		onChangeSketeton(val) {
			this.skeleton = val;
			let text = '';
			if (val.length) {
				this.skeleton.forEach((item, index) => {
					text += `<p>${index + 1}、${this.skeletonList[item - 1].label}</p>`;
				});
				this.skeletonText = `骨质疏松：${text}`;
			} else {
				this.skeletonText = '';
			}
		},
		onChangeGout(val) {
			this.gout = val;
			let text = '';
			if (val.length) {
				this.gout.forEach((item, index) => {
					text += `<p>${index + 1}、${this.goutList[item - 1].label}</p>`;
				});
				this.goutText = `痛风：${text}`;
			} else {
				this.goutText = '';
			}
		},
		onChangeObesity(val) {
			this.obesity = val;
			let text = '';
			if (val.length) {
				this.obesity.forEach((item, index) => {
					text += `<p>${index + 1}、${this.obesityList[item - 1].label}</p>`;
				});
				this.obesityText = `肥胖：${text}`;
			} else {
				this.obesityText = '';
			}
		},
		onChangeStone(val) {
			this.stone = val;
			let text = '';
			if (val.length) {
				this.stone.forEach((item, index) => {
					text += `<p>${index + 1}、${this.stoneList[item - 1].label}</p>`;
				});
				this.stoneText = `结石：${text}`;
			} else {
				this.stoneText = '';
			}
		},
		onChangeScore(val) {
			this.setSportScore(val);
		},
		onChangeSite() {
			this.setAdjustmentSite(this.site);
		},
		onChangeCount(val) {
			this.setAdjustmentCount(val);
		},
	},
	watch: {
		yyAllData() {
			this.site = '';
			this.suggestContent = '';
			this.dieaseContent = '';
			this.suggestValue = [];
			this.diabetes = [];
			this.hypertension = [];
			this.setAdjustmentSite(this.site);
			this.setAdjustmentSuggestion(this.suggestContent);
			this.setAdjustmentDiease(this.dieaseContent);
		},
	},
};
</script>

<style lang="less" scoped>
.adjust-container {
  .score {
    display: inline-block;
    width: 95px;
    height: 95px;
    line-height: 80px;
    text-align: center;
    color: #005ab3;
    font-size: 28px;
    border-radius: 50%;
    border: 10px solid #005ab3;
    margin: 0 30px;
  }
  .adjust-content {
    .adjust-wrap {
      margin-top: 20px;
      &.border {
        border-top: 1px solid #005ab3;
      }
      .title {
        display: inline-block;
        background-color: #005ab3;
        font-size: 20px;
        padding: 5px 28px;
        color: #fff;
        font-weight: 700;
        margin: 15px 0;
      }
      .adjust-list {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #005ab3;
        margin-top: 20px;
        &.adjust-sports {
          padding: 0 100px;
          color: #595757;
          margin-top: 40px;
        }
        > li {
          flex: 1;
          text-align: center;
          .adj-img {
            width: 70px;
          }
          .adj-text {
            font-size: 16px;
            margin-top: 5px;
            margin-bottom: 2px;
          }
          .adj-kcal {
            font-weight: 700;
            font-size: 22px;
          }
        }
      }
      .adjust-textarea {
        display: block;
        width: 100%;
        border: 1px solid #005ab3;
        margin-bottom: 10px;
        padding: 8px;
        .textarea {
          color: #005ab3;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 15px;
        }
        .textarea-content {
          padding: 10px 0;
        }
      }
    }
  }
  .rate-date {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    color: #005ab3;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    > img {
      width: 40px;
    }
  }
}
</style>
