<template>
  <div>
    <layout>
      <div class="content-header">
        <div class="content-header__text">
          <p>饮食习惯</p>
          <p>EATING HABITS</p>
        </div>
        <div class="content-header__img">
          <img src="@/assets/img/quantify-food.png">
        </div>
      </div>
      <div class="flex justify-evenly">
        <div>
          <span>饮食时间：</span>
          <Select :value="habits[0]" style="width:150px;" @on-change="onChangeHabitsTime">
            <Option value="规律"></Option>
            <Option value="不规律"></Option>
          </Select>
        </div>
        <div>
          <span>主要烹饪方式：</span>
          <Select :value="habits[1]" style="width:250px;" @on-change="onChangeCook">
            <Option value="炒、炖、蒸、煮等健康方式"></Option>
            <Option value="煎炸、腌制等不健康方式"></Option>
          </Select>
        </div>
      </div>
      <div class="content-header">
        <div class="content-header__text">
          <p>摄入量评估</p>
          <p>INTAKE ASSESSMENT</p>
        </div>
        <div class="content-header__img">
          <img src="@/assets/img/quantify-drink.png">
        </div>
      </div>
      <div class="content-section">
        <div class="flex justify-evenly">
          <p style="font-weight:bold;">异常差值：<span style="color:red;">{{data.srlpg.yccz}}Kcal</span></p>
          <span class="blue-border">标准饮食摄入总量：{{data.srlpg.bzsrzl}}Kcal</span>
          <span class="orange-border">实际饮食摄入总量：{{data.srlpg.sjsrzl}}Kcal</span>
        </div>
        <div class="axis">
          <div class="axis-label">
            <span class="text-orange">-200</span>
            <span class="text-green" style="padding-left: 26%;">-50</span>
            <span class="text-green" style="padding: 0 8%;">0</span>
            <span class="text-green" style="padding-right: 26%;">+50</span>
            <span class="text-orange">+200</span>
          </div>
          <div class="axis-gap">
            <div class="axis-cursor" :style="calcCursorPosition(data.srlpg.bcsz)">
              <div class="axis-pad"></div>
              <div class="axis-triangle"></div>
              <span>本次数值</span>
              <span>{{ data.srlpg.bcsz }}</span>
            </div>
            <div class="axis-cursor" :style="calcCursorPosition(data.srlpg.scsz)">
              <div class="axis-pad"></div>
              <div class="axis-triangle"></div>
              <span>上次数值</span>
              <span>{{ data.srlpg.scsz }}</span>
            </div>
            <p class="axis-gap-item bg-red" style="width: 10%"></p>
            <p class="axis-gap-item bg-orange" style="width: 30%"></p>
            <p class="axis-gap-item bg-green" style="width: 20%"></p>
            <p class="axis-gap-item bg-orange" style="width: 30%"></p>
            <p class="axis-gap-item bg-red" style="width: 10%"></p>
          </div>
        </div>
        <div class="tip-panel">
          <p class="tip">
            <img src="@/assets/img/quantify-tip.png" width="26">
            <span class="text-18">提示</span>
          </p>
          <p class="tip-text">{{data.srlpg.ts}}</p>
        </div>
      </div>
      <div class="content-header">
        <div class="content-header__text">
          <p>餐次分配比</p>
          <p>MEAL ALLOCATION RATIO</p>
        </div>
      </div>
      <div class="content-section flex justify-evenly">
        <div class="bar-item">
          <div class="bar-panel">
            <div class="bar-graph">
              <div class="bar">
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
              </div>
            </div>
            <div class="bar-cursor" :style="{bottom: `${data.ccfpb.zaoc_bzz * 2}px`}">
              <div><p>标准值</p><p>{{data.ccfpb.zaoc_bzz}}%</p></div>
              <div class="bar-pad"></div>
            </div>
            <div class="bar-value">
              <div class="bar-pad" :style="{height: `${data.ccfpb.zaoc_sjz * 2}px`}"></div>
              <div><p>实际值</p><p>{{data.ccfpb.zaoc_sjz}}%</p></div>
            </div>
          </div>
          <img src="@/assets/img/quantify-am.png" width="40" />
          <span>早餐</span>
        </div>
        <div class="bar-item">
          <div class="bar-panel">
            <div class="bar-graph">
              <div class="bar">
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
              </div>
            </div>
            <div class="bar-cursor" :style="{bottom: `${data.ccfpb.zhongc_bzz * 2}px`}">
              <div><p>标准值</p><p>{{data.ccfpb.zhongc_bzz}}%</p></div>
              <div class="bar-pad"></div>
            </div>
            <div class="bar-value">
              <div class="bar-pad" :style="{height: `${data.ccfpb.zhongc_sjz * 2}px`}"></div>
              <div><p>实际值</p><p>{{data.ccfpb.zhongc_sjz}}%</p></div>
            </div>
          </div>
          <img src="@/assets/img/quantify-pm.png" width="40" />
          <span>中餐</span>
        </div>
        <div class="bar-item">
          <div class="bar-panel">
            <div class="bar-graph">
              <div class="bar">
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
              </div>
            </div>
            <div class="bar-cursor" :style="{bottom: `${data.ccfpb.wc_bzz * 2}px`}">
              <div><p>标准值</p><p>{{data.ccfpb.wc_bzz}}%</p></div>
              <div class="bar-pad"></div>
            </div>
            <div class="bar-value">
              <div class="bar-pad" :style="{height: `${data.ccfpb.wc_sjz * 2}px`}"></div>
              <div><p>实际值</p><p>{{data.ccfpb.wc_sjz}}%</p></div>
            </div>
          </div>
          <img src="@/assets/img/quantify-night.png" width="40" />
          <span>晚餐</span>
        </div>
        <div class="bar-item">
          <div class="bar-panel">
            <div class="bar-graph">
              <div class="bar">
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
                <div class="bar-gap"></div>
              </div>
            </div>
            <div class="bar-cursor" :style="{bottom: `${data.ccfpb.jc_bzz * 2}px`}">
              <div><p>标准值</p><p>{{data.ccfpb.jc_bzz}}%</p></div>
              <div class="bar-pad"></div>
            </div>
            <div class="bar-value">
              <div class="bar-pad" :style="{height: `${data.ccfpb.jc_sjz * 2}px`}"></div>
              <div><p>实际值</p><p>{{data.ccfpb.jc_sjz}}%</p></div>
            </div>
          </div>
          <img src="@/assets/img/quantify-more.png" width="40" />
          <span>加餐</span>
        </div>
      </div>
      <div class="tip-panel">
        <p class="tip">
          <img src="@/assets/img/quantify-tip.png" width="26">
          <span class="text-18">提示</span>
        </p>
        <div class="tip-text">
          <p>早餐：{{data.ccfpb.zaoc_bzts}}</p>
          <p>{{data.ccfpb.zaoc_ts}}</p>
          <p>中餐：{{data.ccfpb.zhongc_bzts}}</p>
          <p>{{data.ccfpb.zhongc_ts}}</p>
          <p>晚餐：{{data.ccfpb.wanc_bzts}}</p>
          <p>{{data.ccfpb.wc_ts}}</p>
        </div>
      </div>
    </layout>
    <layout>
      <div class="content-header">
        <div class="content-header__text">
          <p>三大产能比</p>
          <p>THREE CAPACITY RATIOS</p>
        </div>
      </div>
      <div class="content-section flex2 justify-evenly item-center">
        <span>标准值</span>
        <div class="progress">
          <div class="progress-item bg-blue" :style="{width: `${data.sdcnb.dbz_bjz}%`}">
            {{ data.sdcnb.dbz_bjz }}%
          </div>
          <div class="progress-item bg-orange" :style="{width: `${data.sdcnb.zf_bjz}%`}">
            {{ data.sdcnb.zf_bjz }}%
          </div>
          <div class="progress-item bg-red" :style="{width: `${data.sdcnb.tshhw_bjz}%`}">
            {{ data.sdcnb.tshhw_bjz }}%
          </div>
        </div>
      </div>
      <div class="content-section flex2 justify-evenly item-center text-orange">
        <span>实际值</span>
        <div class="progress">
          <div class="progress-item bg-blue" :style="{width: `${data.sdcnb.dbz_sjz}%`}">
            {{ data.sdcnb.dbz_sjz }}%
          </div>
          <div class="progress-item bg-orange" :style="{width: `${data.sdcnb.zf_sjz}%`}">
            {{ data.sdcnb.zf_sjz }}%
          </div>
          <div class="progress-item bg-red" :style="{width: `${data.sdcnb.tshhw_sjz}%`}">
            {{ data.sdcnb.tshhw_sjz }}%
          </div>
        </div>
      </div>
      <div class="content-section flex2 justify-evenly item-center">
        <div class="mx-20">
          <img src="@/assets/img/quantify-dbz.png" width="260">
          <p class="text-center">蛋白质</p>
          <p class="mb20">
            <img src="@/assets/img/quantify-tip.png" width="26">
            <span class="text-18">提示</span>
          </p>
          <p v-html="replaceBreak(data.sdcnb.dbz_ts)"></p>
        </div>
        <div class="mx-20">
          <img src="@/assets/img/quantify-zf.png" width="260">
          <p class="text-center">脂肪</p>
          <p class="mb20">
            <img src="@/assets/img/quantify-tip.png" width="26">
            <span class="text-18">提示</span>
          </p>
          <p v-html="replaceBreak(data.sdcnb.zf_ts)"></p>
        </div>
        <div class="mx-20">
          <img src="@/assets/img/quantify-ts.png" width="260">
          <p class="text-center">碳水化合物</p>
          <p class="mb20">
            <img src="@/assets/img/quantify-tip.png" width="26">
            <span class="text-18">提示</span>
          </p>
          <p v-html="replaceBreak(data.sdcnb.tshhw_ts)"></p>
        </div>
      </div>
    </layout>
  </div>
</template>

<script>
import Layout from './layout';
import { mapMutations } from 'vuex';

export default {
	data() {
		return {
			habits: [],
		};
	},
	components: {
		Layout,
	},
	props: {
		data: {
			type: Object,
		},
	},
	methods: {
		...mapMutations(['setHabits']),
		replaceBreak(val) {
			if (val) return val.replace(/\r/g, '<br>');
			else return '';
		},
		calcCursorPosition(val) {
			if (val > 250) return { left: '100%' };
			if (val < -250) return { left: 0 };
			return { left: `${((250 + val) / 500) * 100}%` };
		},
		onChangeHabitsTime(val) {
			this.habits[0] = val;
			this.setHabits(this.habits);
		},
		onChangeCook(val) {
			this.habits[1] = val;
			this.setHabits(this.habits);
		},
	},
};
</script>

<style lang="less" scoped>
.content-header {
  display: flex;
  color: #005AB3;
  padding-bottom: 20px;
  margin: 30px 0;
  border-bottom: 1px solid #005AB3;
  p:first-child {
    font-size: 28px;
    font-weight: bold;
  }
  &__text {
    margin-right: 20px;
    border-left: 6px solid #005AB3;
    padding-left: 18px;
  }
  &__img {
    width: 65px;
    img {
      width: 100%;
    }
  }
}
.flex {
  display: flex;
  color: #005AB3;
  font-size: 20px;
  margin: 30px 0;
}
.flex2 {
  display: flex;
  color: #005AB3;
  font-size: 22px;
  margin: 80px 60px;
  font-weight: bold;
}
.justify-evenly {
  justify-content: space-evenly;
}
.blue-border {
  font-size: 16px;
  padding-bottom: 5px;
  border-bottom: 1px solid #005AB3;
}
.orange-border {
  color: orange;
  font-size: 16px;
  padding-bottom: 5px;
  border-bottom: 1px solid orange;
}
.axis {
  position: relative;
  color: #005AB3;
  font-size: 12px;
  height: 100px;
  width: 70%;
  margin: 0 auto;
}
.axis-cursor {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: -4px;
  margin-left: -25px;
}
.axis-pad {
  height: 30px;
  background-color: #005AB3;
  width: 4px;
  border-radius: 3px;
}
.axis-triangle {
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom: 10px solid #005AB3;
  margin: -5px 0 5px;
}
.axis-label {
  text-align: center;
  margin-bottom: 5px;
}
.axis-gap {
  width: 100%;
  display: flex;
}
.axis-gap-item {
  height: 24px;
}
.text-green {
  color: #27bb0f;
}
.text-orange {
  color: #f0b71f;
}
.bg-green {
  background-color: #27bb0f;
}
.bg-orange {
  background-color: #f0b71f;
}
.bg-red {
  background-color: #ff736a;
}
.tip-panel {
  display: flex;
  margin: 30px;
}
.tip {
  color: #005AB3;
}
.tip-text {
  font-size: 16px;
  font-weight: bold;
  margin: 3px 0 0 25px;
  p:nth-child(even) {
    text-indent: 3em;
    color: red;
  }
}
.text-18 {
  font-size: 18px;
  padding-left: 10px;
  font-weight: bold;
  vertical-align: middle;
}
.mx-20 {
  margin: 0 20px;
}
.progress {
  width: 80%;
  border-radius: 40px;
  display: flex;
  overflow: hidden;
  &-item {
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    text-align: center;
  }
  .bg-blue {
    background-color: #63aef9;
  }
  .bg-orange {
    background-color: #ff821e;
  }
  .bg-red {
    background-color: #df4006;
  }
}
.text-center {
  text-align: center;
  margin: 30px 0 60px;
}
.bar-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 140px;
  align-items: center;
  img {
    margin: 10px 0;
  }
  span {
    color: #005AB3;
    font-size: 16px;
    font-weight: bold;
  }
}
.bar-panel {
  width: 140px;
  position: relative;
}
.bar-graph {
  width: 40px;
  border-radius: 8px;
  background-color: #efefef;
  padding: 5px 0;
  margin: auto;
  .bar {
    height: 200px;
    border-radius: 8px;
    background-color: #bbdefb;
    width: 30px;
    margin: auto;
    padding: 10px 0;
    box-sizing: content-box;
  }
  .bar-gap {
    height: 20px;
    border-bottom: 1px solid #63aef9;
    margin: 0 5px;
    &:first-child {
      border-top: 1px solid #63aef9;
    }
  }
}
.bar-cursor {
  color: #005AB3;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  .bar-pad {
    height: 3px;
    width: 40px;
    background-color: #005AB3;
    border-radius: 3px;
    margin: 18px 0 0 14px;
  }
}
.bar-value {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 12px;
  color: #ff821e;
  display: flex;
  .bar-pad {
    height: 0;
    width: 20px;
    background-color: #ff821e;
    margin: 0 24px 16px 0;
    opacity: .6;
  }
}
</style>
