<template>
	<div class="week-rate-component">
		<div class="week-desc-wrap">
			<Form :label-width="160">
				<Row :gutter="10">
					<Col span="6">
					<Form-item label="规律性:">
						<Select v-model="form.regular" clearable @on-change="changeRegular">
							<Option v-for="(item, index) in select1" :key="index" :value="item.value">{{ item.label }}</Option>
						</Select>
					</Form-item>
					</Col>
					<Col span="6">
					<Form-item label="数据完整性:">
						<Select v-model="form.data" clearable @on-change="changeData">
							<Option v-for="(item, index) in select1" :key="index" :value="item.value">{{ item.label }}</Option>
						</Select>
					</Form-item>
					</Col>
					<Col span="6">
					<Form-item label="运动装备:">
						<Select v-model="form.devide" clearable @on-change="changeDevide">
							<Option v-for="(item, index) in select1" :key="index" :value="item.value">{{ item.label }}</Option>
						</Select>
					</Form-item>
					</Col>
					<Col span="6">
					<Form-item label="运动场地:">
						<Select v-model="form.site" clearable @on-change="changeSite">
							<Option v-for="(item, index) in select1" :key="index" :value="item.value">{{ item.label }}</Option>
						</Select>
					</Form-item>
					</Col>
				</Row>
				<Row :gutter="10">
					<Col span="6">
					<Form-item label="运动前后是否测量血压:">
						<Select v-model="form.blood" clearable @on-change="changeBlood">
							<Option v-for="(item, index) in select1" :key="index" :value="item.value">{{ item.label }}</Option>
						</Select>
					</Form-item>
					</Col>
					<Col span="6">
					<Form-item label="运动频率:">
						<Select v-model="form.frequency" clearable @on-change="changeFrequency">
							<Option v-for="(item, index) in select2" :key="index" :value="item.value">{{ item.label }}</Option>
						</Select>
					</Form-item>
					</Col>
					<Col span="6">
					<Form-item label="运动安全:">
						<Select v-model="form.safe" clearable @on-change="changeSafe">
							<Option v-for="(item, index) in select1" :key="index" :value="item.value">{{ item.label }}</Option>
						</Select>
					</Form-item>
					</Col>
					<Col span="6">
					<Form-item label="运动进阶:">
						<Select v-model="form.forward" clearable @on-change="changeForward">
							<Option v-for="(item, index) in select1" :key="index" :value="item.value">{{ item.label }}</Option>
						</Select>
					</Form-item>
					</Col>
				</Row>
			</Form>
		</div>
		<div class="week-rate">
			<div class="week-text-card">
				<img src="@/assets/img/manage/info-icon.png" alt="img" class="week-rate-icon" />
				<span class="week-rate-text">周运动评价：</span>
			</div>
			<div class="week-textarea">
				<!-- <Button type="warning" @click="selectModal=true" style="font-size: 14px;" class="select-button" v-if="visible">选择问题</Button> -->
				<div class="textarea-content">
					<div v-html="content"></div>
					<p class="normal" v-if="normalContent">正常</p>
					<div v-html="normalContent"></div>
					<p class="normal" style="margin-top: 20px;" v-if="imnormalContent">风险</p>
					<div v-html="imnormalContent"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import ReportSportsService from '@/services/reportSportsService';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
	data() {
		return {
			normalContent: '',
			imnormalContent: '',
			content: '',
			select1: [
				{
					label: '是',
					value: 1,
				},
				{
					label: '否',
					value: 0,
				},
			],
			select2: [
				{
					label: '合适',
					value: 1,
				},
				{
					label: '过多',
					value: 2,
				},
				{
					label: '过少',
					value: 3,
				},
			],
			form: {
				regular: 1,
				data: 1,
				devide: 1,
				site: 1,
				blood: 1,
				frequency: 1,
				safe: 1,
				forward: 1,
			},
			selectRegular: [
				{
					label: '能帮助消耗多余的脂肪，同时适量的运动还能抑制食欲；可以减缓肌肉减少症',
					value: 1,
				},
				{
					label:
						'可以维持或增加心肌氧的供应，减少心肌工作量消耗;提高心肌的功能和电稳定性;血管储备力增强;提高抗动脉粥样硬化能力',
					value: 2,
				},
				{
					label:
						'坚持12周以上的规律运动，你就能显著提高免疫力，因为运动可使中性粒细胞急剧升高，可增强自然杀伤细胞、巨噬细胞和T淋巴细胞的活力，而这些细胞能吞噬身体入侵的病毒',
					value: 3,
				},
			],
			selectNoRegular: [
				{
					label: '一般无规律的锻炼，会使机体在受到一定刺激后，突然产生肌肉的酸痛感。',
					value: 11,
				},
				{
					label:
						'很容易造成运动损伤，轻者会引起肌肉酸痛，重则则会造成肌肉拉伤，这样不仅达不到运动的效果，反而损害了身体的健康。',
					value: 12,
				},
				{
					label:
						'无规律的锻炼，会使得机体突然承受一定的负担，造成“内伤”但不坚持下去，只会使得活动能力停滞不前。',
					value: 13,
				},
			],
			selectData: [
				{
					label: '能准确的了解您实际的运动情况及误区，给予有效的评估与针对性的指导',
					value: 21,
				},
			],
			selectNoData: [
				{
					label: '不能很准确的了解您实际的运动情况及误区，给予有效的评估与针对性的指导',
					value: 22,
				},
			],
			selectDevide: [
				{
					label: '运动鞋能有效减轻运动跑跳时对膝关节的压力，同时起到减震防滑，避免摔跤',
					value: 31,
				},
				{
					label: '适合的运动衣能及时排汗不粘连，预防感冒',
					value: 32,
				},
			],
			selectNoDevide: [
				{
					label: '容易增加关节负担及运动风险',
					value: 33,
				},
			],
			selectSite: [
				{
					label: '能有效提升运动效率，同时能有效改善心肺功能',
					value: 41,
				},
			],
			selectNoSite: [
				{
					label: '会大大增加运动风险，也会对肺功能有影响',
					value: 42,
				},
			],
			selectBlood: [
				{
					label: '能有效规避运动可能带来的心脑血管问题的',
					value: 51,
				},
			],
			selectNoBlood: [
				{
					label: '不能规避有循环问题的风险，也不能及时调整最恰当的运动时间点，帮助改善身体机能',
					value: 52,
				},
			],
			selectFrequency: [
				{
					label:
						'能够有效的刺激机体后很好的修复提升，达到最适合的损伤与修复，让机体能更好的正向提升',
					value: 61,
				},
			],
			selectNoMoreFrequency: [
				{
					label:
						'影响内分泌可造成下丘脑-垂体-性腺轴功能抑制，血睾酮水平下降，表现为兴奋性差，竞争意识下降，体力恢复慢',
					value: 62,
				},
				{
					label:
						'身体抵抗力下降使机体功能受到影响，特别是当身体运动所消耗的能量与机体所摄取的能量不平衡时，会影响身体正常的代谢，身体体重下降，消瘦，抵抗力下降',
					value: 63,
				},
				{
					label:
						'导致贫血大量的排汗会使机体中的铁元素也随之排出，长期如此会增加运动性贫血的发生率，这种贫血多为缺铁性贫血，表现为剧烈运动过后面色苍白，头晕目眩，四肢无力，精神萎靡，建议运动前后应及时补充维C',
					value: 64,
				},
			],
			selectNoLessFrequency: [
				{
					label: '达不到设定的平衡消耗目标，从而改善体质，达到细胞活性增强，衰退减缓的功效。',
					value: 65,
				},
				{
					label:
						'人的新陈代谢机能降低，由此很容易引起各种肌肉关节疾病，如肩周炎、骨质疏松等，同时也会导致心肺机能下降等不良身体反应。',
					value: 66,
				},
			],
			selectSafe: [
				{
					label: '减少运动损伤风险',
					value: 71,
				},
				{
					label: '降低心脏负担，增加心脏负荷',
					value: 72,
				},
			],
			selectNoSafe: [
				{
					label:
						'超过靶心率，会对身体健康不利，导致恶心、头晕、胸闷，糖尿病患者则会使血糖急剧降低，而且减脂效果也不好。',
					value: 73,
				},
				{
					label: '会加重心脏负担，增加心脏负荷。',
					value: 74,
				},
				{
					label: '增加摔跤的风险。',
					value: 75,
				},
			],
			selectForward: [
				{
					label: '能分阶段的提升运动机能，逐步改善系统器官功能',
					value: 81,
				},
			],
			selectNoForward: [
				{
					label: '身体提升的会很慢，同时也会形成一定程度的功能下降',
					value: 82,
				},
			],
			regularText: '',
			regularNoText: '',
			dataText: '',
			dataNoText: '',
			devideText: '',
			devideNoText: '',
			siteText: '',
			siteNoText: '',
			bloodText: '',
			bloodNoText: '',
			frequencyText: '',
			frequencyMoreText: '',
			frequencyLessText: '',
			safeText: '',
			safeNoText: '',
			fowrardText: '',
			forwardNoText: '',
		};
	},
	computed: {
		...mapGetters({
			data: 'yyAllData',
			visible: 'selectVisible',
			startTime: 'start_time',
		}),
	},
	methods: {
		...mapActions(['setSportDesc']),
		...mapMutations(['setSportScore', 'setWeekFillData']),
		// sureHandle() {
		// 	let sj =
		// 		`1_${this.modal.regular}-2_${this.modal.data}-3_${this.modal.devide}-4_${this.modal.site}-5_${this.modal.blood}-6_${this.modal.frequency}-7_${this.modal.safe}-8_${this.modal.forward}`
		// 	console.log(sj)
		// 	ReportSportsService.weekRate({
		// 		member_id: localStorage.getItem('member_id'),
		// 		sj: sj
		// 	}).then(res => {
		// 		this._getSportQuestion()
		// 	})
		// },
		_getSportQuestion() {
			let sj = `${this.form.regular}-${this.form.data}-${this.form.devide}-${this.form.site}-${this.form.blood}-${this.form.frequency}-${this.form.safe}-${this.form.forward}`;
			this.setWeekFillData(sj);
			ReportSportsService.questionSports({
				member_id: localStorage.getItem('member_id'),
				sj: sj,
				qtyxxh: this.data.zydpj.qtyxxh,
				bzyxxhl: this.data.rydpj.bzyxxhl,
				yd_start: this.startTime,
				yd_end: dayjs(this.startTime).add(6, 'd').format('YYYY-MM-DD'),
			}).then((res) => {
				this.setSportDesc(res);
				console.log(res.score);
				this.setSportScore(res.score);
				this.joinContent(res);
			});
		},
		changeRegular(val) {
			this.form.regular = val;
			this.initRegular();
			this._getSportQuestion();
		},
		changeData(val) {
			this.form.data = val;
			this.initData();
			this._getSportQuestion();
		},
		changeDevide(val) {
			this.form.devide = val;
			this.initDevide();
			this._getSportQuestion();
		},
		changeSite(val) {
			this.form.site = val;
			this.initSite();
			this._getSportQuestion();
		},
		changeBlood(val) {
			this.form.blood = val;
			this.initBlood();
			this._getSportQuestion();
		},
		changeFrequency(val) {
			this.form.frequency = val;
			this.initFrequency();
			this._getSportQuestion();
		},
		changeSafe(val) {
			this.form.safe = val;
			this.initSafe();
			this._getSportQuestion();
		},
		changeForward(val) {
			this.form.forward = val;
			this.initForward();
			this._getSportQuestion();
		},
		initRegular() {
			this.regularText = '';
			this.regularNoText = '';
			if (this.form.regular === 1) {
				this.selectRegular.forEach((item, index) => {
					this.regularText += `<p>${index + 1}、${item.label}</p>`;
				});
			} else {
				this.selectNoRegular.forEach((item, index) => {
					this.regularNoText += `<p>${index + 1}、${item.label}</p>`;
				});
			}
		},
		initData() {
			this.dataText = '';
			this.dataNoText = '';
			if (this.form.data === 1) {
				this.selectData.forEach((item, index) => {
					this.dataText += `<p>${index + 1}、${item.label}</p>`;
				});
			} else {
				this.selectNoData.forEach((item, index) => {
					this.dataNoText += `<p>${index + 1}、${item.label}</p>`;
				});
			}
		},
		initDevide() {
			this.devideText = '';
			this.devideNoText = '';
			if (this.form.devide === 1) {
				this.selectDevide.forEach((item, index) => {
					this.devideText += `<p>${index + 1}、${item.label}</p>`;
				});
			} else {
				this.selectNoDevide.forEach((item, index) => {
					this.devideNoText += `<p>${index + 1}、${item.label}</p>`;
				});
			}
		},
		initSite() {
			this.siteText = '';
			this.siteNoText = '';
			if (this.form.site === 1) {
				this.selectSite.forEach((item, index) => {
					this.siteText += `<p>${index + 1}、${item.label}</p>`;
				});
			} else {
				this.selectNoSite.forEach((item, index) => {
					this.siteNoText += `<p>${index + 1}、${item.label}</p>`;
				});
			}
		},
		initBlood() {
			this.bloodText = '';
			this.bloodNoText = '';
			if (this.form.blood === 1) {
				this.selectBlood.forEach((item, index) => {
					this.bloodText += `<p>${index + 1}、${item.label}</p>`;
				});
			} else {
				this.selectNoBlood.forEach((item, index) => {
					this.bloodNoText += `<p>${index + 1}、${item.label}</p>`;
				});
			}
			console.log(this.bloodText, this.bloodNoText);
		},
		initFrequency() {
			this.frequencyText = '';
			this.frequencyLessText = '';
			this.frequencyMoreText = '';
			if (this.form.frequency === 1) {
				this.selectFrequency.forEach((item, index) => {
					this.frequencyText += `<p>${index + 1}、${item.label}</p>`;
				});
			} else if (this.form.frequency === 2) {
				this.selectNoMoreFrequency.forEach((item, index) => {
					this.frequencyMoreText += `<p>${index + 1}、${item.label}</p>`;
				});
			} else {
				this.selectNoLessFrequency.forEach((item, index) => {
					this.frequencyLessText += `<p>${index + 1}、${item.label}</p>`;
				});
			}
		},
		initSafe() {
			this.safeText = '';
			this.safeNoText = '';
			if (this.form.safe === 1) {
				this.selectSafe.forEach((item, index) => {
					this.safeText += `<p>${index + 1}、${item.label}</p>`;
				});
			} else {
				this.selectNoSafe.forEach((item, index) => {
					this.safeNoText += `<p>${index + 1}、${item.label}</p>`;
				});
			}
		},
		initForward() {
			this.fowrardText = '';
			this.forwardNoText = '';
			if (this.form.forward === 1) {
				this.selectForward.forEach((item, index) => {
					this.fowrardText += `<p>${index + 1}、${item.label}</p>`;
				});
			} else {
				this.selectNoForward.forEach((item, index) => {
					this.forwardNoText += `<p>${index + 1}、${item.label}</p>`;
				});
			}
		},
		initHandle() {
			this.initRegular();
			this.initData();
			this.initDevide();
			this.initSite();
			this.initBlood();
			this.initFrequency();
			this.initSafe();
			this.initForward();
			this._getSportQuestion();
		},
		joinContent(res) {
			this.content = `<div class="context" style="color: #ff9900;margin-bottom: 6px"><span style="font-size: 18px">${res.zydpj[0].bt}：</span>${res.zydpj[0].ms}</div>`;
			let regularContent =
				this.regularText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动规律：</p>${this.regularText}</div>`
					: '';
			let regularNoContent =
				this.regularNoText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动不规律：</p>${this.regularNoText}</div>`
					: '';
			let dataContent =
				this.dataText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">数据完整：</p>${this.dataText}</div>`
					: '';
			let dataNoContent =
				this.dataNoText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">数据不完整：</p>${this.dataNoText}</div>`
					: '';
			let deviceContent =
				this.devideText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动装备齐全：</p>${this.devideText}</div>`
					: '';
			let deviceNoContent =
				this.devideNoText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动装备不齐全：</p>${this.devideNoText}</div>`
					: '';
			let siteContent =
				this.siteText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动场地安全：</p>${this.siteText}</div>`
					: '';
			let siteNoContent =
				this.siteNoText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动场地不安全：</p>${this.siteNoText}</div>`
					: '';
			let bloodContent =
				this.bloodText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动前后测量血压：</p>${this.bloodText}</div>`
					: '';
			let bloodNoContent =
				this.bloodNoText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动前后没有测量血压：</p>${this.bloodNoText}</div>`
					: '';
			let frequencyContent =
				this.frequencyText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动频率合适：</p>${this.frequencyText}</div>`
					: '';
			let frequencyMoreContent =
				this.frequencyMoreText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动频率过多：</p>${this.frequencyMoreText}</div>`
					: '';
			let frequencyLessContent =
				this.frequencyLessText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动频率过少：</p>${this.frequencyLessText}</div>`
					: '';
			let safeContent =
				this.safeText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动安全：</p>${this.safeText}</div>`
					: '';
			let safeNoContent =
				this.safeNoText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动不安全：</p>${this.safeNoText}</div>`
					: '';
			let forwardContent =
				this.fowrardText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动进阶：</p>${this.fowrardText}</div>`
					: '';
			let forwardNoContent =
				this.forwardNoText.length > 0
					? `<div style="margin-bottom: 6px"><p style="font-size: 18px">运动不进阶：</p>${this.forwardNoText}</div>`
					: '';

			this.normalContent =
				regularContent +
				dataContent +
				deviceContent +
				siteContent +
				bloodContent +
				frequencyContent +
				safeContent +
				forwardContent;
			this.imnormalContent =
				regularNoContent +
				dataNoContent +
				deviceNoContent +
				siteNoContent +
				bloodNoContent +
				frequencyMoreContent +
				frequencyLessContent +
				safeNoContent +
				forwardNoContent;
		},
	},
	mounted() {
		this.initHandle();
	},
};
</script>

<style lang="less" scoped>
	.week-desc-wrap {
		padding: 20px 30px;
	}

	.week-rate {
		.week-text-card {
			margin-bottom: 15px;
		}

		.week-rate-icon {
			width: 40px;
			margin-right: 10px;
		}

		.week-rate-text {
			font-size: 18px;
			font-weight: 700;
			color: #005ab3;
			margin-right: 10px;
		}

		.week-textarea {
			border: 1px solid #005ab3;
			padding: 8px;
			flex: 1;
			min-height: 90px;
			border-radius: 4px;
			line-height: 30px;
			font-size: 16px;
			font-weight: 700;
			color: #ff9900;

			.textarea-content {
				// max-height: 260px;
				// overflow-y: auto;
				margin-top: 12px;
				.normal {
					font-size: 20px;
					font-weight: 700;
				}
			}
		}
	}
</style>
